import { Form, InputNumber, Table, DatePicker, Space, Button, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { encryptedData, showErrorToast } from "../lib/helpers/helperFunctions";
import { addOrUpdateSubCollection, deleteSubCollectionItem } from "lib/firebase/db";
import firebase from "../lib/firebase/firebase";
import dayjs from "dayjs";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons";
const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [currentUserId, setCurrentUserId] = useState(null);
  const [subCollectionName, setSubCollectionName] = useState(null);
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [changedDate, setChangedDate] = useState("");
  const [getAddingRowKey, setAddingRowKey] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const isEditing = (record) => record.key === editingKey;
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();

    // Cleanup function
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  useEffect(() => {
    setData(props.data);
    setCurrentUserId(props.userId)
    setSubCollectionName(props.subCollectionName)
  }, [props.data]);

  useEffect(() => {
    setEditingKey("");
    setAddingRowKey(null);
  }, [props.modelClose]);

  const edit = (record) => {
    form.setFieldsValue({
      date: "",
      value: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const onChange = async (date) => {
    setChangedDate(date.format("YYYY-MM-DD HH:mm:ss"));
  };


  const EditableCell = ({ editing, dataIndex, title, inputType, children, ...restProps }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber min="1" />
      ) : (
        <Space direction="vertical" size={12}>
          <DatePicker
            allowClear={false}
            defaultValue={changedDate ? dayjs(changedDate) : dayjs(children[1])}
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            onOk={onChange}
            size="middle"
            maxDate={dayjs(dayjs().format("YYYY-MM-DD"), 'YYYY-MM-DD')}
            showNow={false}
          />
        </Space>
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const cancel = () => {
    setEditingKey("");
    if (getAddingRowKey) {
      const filteredArray = data.filter(item => item.key !== getAddingRowKey);
      setData(filteredArray);
      setAddingRowKey(null);
    }
  };

  const save = async (key) => {
    try {
      let row = await form.validateFields();
      row = { ...row, ...{ date: changedDate ? changedDate : row?.date } };
      const newData = [...data];
      addOrUpdateSubCollection(key?.userId, key?.subCollectionName, key?.id, {
        date: firebase.firestore.Timestamp.fromDate(
          new Date(changedDate ? changedDate : row?.date)
        ),
        value: encryptedData(row?.value),
      });
      const index = newData.findIndex((item) => key.key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setAddingRowKey(null);
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      // showErrorToast("Something went wrong!");
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      editable: true,
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      sortDirections: ["ascend", "descend"],
      // defaultSortOrder: 'descend',
    },
    {
      title: "Value",
      dataIndex: "value",
      sorter: (a, b) => a.value - b.value,
      sortDirections: ["ascend", "descend"],
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "20%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button type="primary" size={isMobile ? "small" : "default"} style={{ marginRight: 10 }} icon={<CheckCircleOutlined />} onClick={() => save(record)}>
              Save
            </Button>
            <Button type="primary" danger size={isMobile ? "small" : "default"} icon={<CloseCircleOutlined />} onClick={() => cancel(record)}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Space>
            <Button type="primary" size={isMobile ? "small" : "default"} icon={<EditOutlined />} onClick={() => edit(record)}>
              Edit
            </Button>
            <Popconfirm title="Are you sure to delete this record?" onConfirm={() => deleteRecord(record)}>
              <Button type="primary" danger size={isMobile ? "small" : "default"} icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>
          </Space>

        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "value" ? "number" : "date",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = () => {
    const key = Math.random();
    const newData = {
      key,
      date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      value: null,
      userId: currentUserId,
      subCollectionName: subCollectionName,
    };
    setData([newData, ...data,]);
    edit(newData);
    setAddingRowKey(key);
  };

  const deleteRecord = async (record) => {
    try {
      deleteSubCollectionItem(record?.userId, record?.subCollectionName, record?.id).then((response) => {
        setData(data.filter(item => item.id !== record.id));
        setAddingRowKey(null);
      })
    } catch (errInfo) {
      setAddingRowKey(null);
      showErrorToast("Something went wrong!");
    }
  };

  return (
    <>
      <Button
        onClick={handleAdd}
        type="primary"
        disabled={getAddingRowKey}
        className="btn btn-primary"
        style={{
          marginBottom: 16,
        }}
        icon={<PlusOutlined />}
      > Add New Record </Button>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName={() => 'editable-row'}
          pagination={{
            onChange: cancel,
            hideOnSinglePage: true,
          }}
        />
      </Form>
    </>
  );
};
export default EditableTable;
