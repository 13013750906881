import React, { useState } from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import {
    Button,
    Layout,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Typography,
    Space,
    message
} from 'antd';
import { createFreeUser } from '../../../lib/firebase/functions';
import Breadcrumbs from '../../shared/breadcrumb';
const { Content } = Layout;
const { Title, Text } = Typography;


const User = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [showLoader, setShowLoader] = useState(false);
    const breadcrumbItems = [
        { label: 'Dashboard', href: '/' },
        { label: 'Super-Admin', href: '/super-admin' },
        { label: 'User' },
        { label: 'Create' },
    ];

    const alertMessage = (type, content) => {
        messageApi.open({
            type,
            content,
            style: {
                marginTop: '5vh',
            },
        });
    }

    const onFinish = (values) => {
        console.log(values)
        setShowLoader(true);
        createFreeUser(values).then(resultData => {
            alertMessage('success', resultData.data.message);
        }).catch(err => {
            console.log(err);
            alertMessage('error', err.message || 'Something Went Wrong');
        }).finally(() => {
            setShowLoader(false);
            form.resetFields();
        });
    };

    return (
        <>
            {contextHolder}

            <Content className='mt-5 mt-sm-4  '>
                <Breadcrumbs items={breadcrumbItems} />
                <div className='mt-5'>
                    <Title level={3} className="text-primary fw-bold mb-0 text-uppercase">Register User</Title>
                    <Text type="secondary" className='ps-1 text-capitalize' >Create free trial user</Text>
                    <Form
                        className='mt-4'
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        size={'large'}
                        scrollToFirstError
                        layout={'vertical'}
                        disabled={showLoader}
                    >
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    hasFeedback
                                    validateFirst
                                    name="email"
                                    required={false}

                                    label={
                                        <span className="fw-bold text-primary">Email Address <span className='text-danger'>*</span> </span>

                                    }
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid email address!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your email address!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Email Address'
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="password"
                                    required={false}
                                    label={
                                        <span className="fw-bold text-primary">Password <span className='text-danger'>*</span></span>
                                    }
                                    hasFeedback
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        {
                                            min: 8,
                                            message: 'Password must be at least 8 characters long!',
                                        },
                                        {
                                            max: 20,
                                            message: 'Password cannot exceed 20 characters!',
                                        },
                                        {
                                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                                            message: 'Password must contain at least one uppercase letter, one lowercase letter, and one numeric digit!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder='Password' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>

                                <Form.Item
                                    name="firstName"
                                    required={false}
                                    label={
                                        <span className="fw-bold text-primary">First Name <span className='text-danger'>*</span></span>
                                    }
                                    hasFeedback
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your first name!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='First Name' />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>

                                <Form.Item
                                    hasFeedback
                                    validateFirst
                                    name="lastName"
                                    required={false}
                                    label={
                                        <span className="fw-bold text-primary">Last Name <span className='text-danger'>*</span></span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your last name!',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Last Name' />
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={16}>

                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="days"
                                    label={
                                        <span className="fw-bold text-primary">Number Of Days <Text type="secondary" className='fw-normal' >(Optional)</Text> </span>
                                    }
                                >
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <InputNumber
                                            placeholder='Default 14 Days'
                                            min={1}
                                            max={365}
                                            changeOnWheel={true}
                                            style={{
                                                width: '100%',
                                                minWidth: "250px"
                                            }}
                                            // className='w-100'
                                            addonAfter={'Days'}
                                            onChange={(event) => {
                                                const value = event?.target?.value;
                                                if (/^\d*$/.test(value)) {
                                                    form.setFieldsValue({ days: value });
                                                }
                                            }}
                                            onKeyPress={(event) => {
                                                // Prevent non-numeric characters from being typed
                                                if (!/[0-9]/.test(event?.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>

                                </Form.Item>
                            </Col>


                        </Row>

                        <Row>
                            <Col>
                                <Form.Item
                                    name="subscribeToNewsletter"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Checkbox >
                                        Subscribe to the newsletter for the latest updates and offers.
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div class="row">
                            <div class="col ">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" loading={showLoader} size={'large'} icon={<UserAddOutlined />} htmlType="submit" className="btn btn-primary " style={{ minWidth: '150px' }}>
                                                Register
                                            </Button>
                                            <Button htmlType="reset" className="btn btn-secondary" style={{ minWidth: '150px' }}>Reset</Button>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                    </Form>
                </div>
            </Content>
        </>
    );
};
export default User;
